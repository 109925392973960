// Home.js - Main Webpage at '/'
import React from 'react';
import { Link } from 'react-router-dom';
import Banner from './Banner';

function Home() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <h1 style={{ fontSize: '4em', textAlign: 'center', color: 'black', marginTop: '-30vh' }}>Coming soon!</h1>
      <h2 style={{ fontSize: '2em', textAlign: 'center', color: 'black' }}>Your future home for NHL data and analysis</h2>
    </div>
  );
}

export default Home;